<template>
	<div class="talentRecruitmentList">
		<!-- 头部 -->
        <headers :haveEmpty="true"></headers>
        <!-- 面包屑 -->
        <crumbs :listData="listData"></crumbs>
		<!-- 内容 -->
		<div class="conentBox">
			<div class="positionList">
				<div class="head">在招职位</div>
				<div class="screentext">
					<div class="title">职位筛选</div>
					<div class="result">
						<span>{{totalNum}}结果</span>
						<span @click="clearSearch()">清除</span>
					</div>
				</div>
				<!-- 筛选输入框 -->
				<div class="inputBox">
					<div class="inputs">
						<div class="left">
							<i class="el-icon-search"></i>
							<input type="text" v-model="Titile" placeholder="搜索职位关键词">
						</div>
						<div class="btns" @click="searchJob">搜索职位</div>
					</div>
				</div>
				<!-- 筛选下拉 -->
				<div class="selectBox">
					<el-select v-model="value" clearable placeholder="请选择招聘类型">
						<el-option
							v-for="item in options"
							:key="item.value"
							:label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
					<el-select v-model="jobValue" clearable placeholder="请选择职位性质">
						<el-option
						v-for="item in jobOptions"
						:key="item.value"
						:label="item.label"
						:value="item.value">
						</el-option>
					</el-select>
					<el-select v-model="areaValue" clearable placeholder="请选择工作地点">
						<el-option
						v-for="item in areaOptions"
						:key="item.value"
						:label="item.label"
						:value="item.value">
						</el-option>
					</el-select>
				</div>
				<!-- 职位列表展示 -->
				<div class="listBox">
					<div class="items" v-for="item in jobList" :key="item">
						<div class="top">
							<div class="titles">
								<div class="status">急</div>
								<div class="title" @click="tiDetial(item)">{{item.titile}}</div>
							</div>
							<div class="times">发布于2023/05/18</div>
						</div>
						<div class="tips">
							<span>{{item.salary}}</span>
							<span>{{item.nature}}</span>
						</div>
					</div>
				</div>
				<!-- 分页 -->
				<div class="pages">
                    <el-pagination
                        class="pagination"
                        layout="prev, pager, next"
                        :total="totalNum"
                        :current-page="pageNum"
                        :page-size="pageSize"
                        @current-change="pageChange">
                    </el-pagination>
                </div>
			</div>
			<div class="rightBox">
				<div class="title">最新职位</div>
				<div class="conList">
					<div class="items" v-for="item in newJobList" :key="item">
						<div class="status">急</div>
						<div class="names" @click="tiDetial(item)">{{item.titile}}</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 底部 -->
        <footers :bg="'#EFF0F0'"></footers>
	</div>
</template>

<script>
import headers from '../../components/header.vue'
import crumbs from '../../components/crumbs.vue'
import footers from '../../components/footers.vue'
import {JobList,commomList} from '../../server/api'
export default {
	components:{
        headers,
        crumbs,
		footers
    },
	data(){
		return {
			listData:['首页','人才招聘'],

			options: [{
				value: '社会招聘',
				label: '社会招聘'
			}, {
				value: '其他招聘',
				label: '其他招聘'
			}, {
				value: '校园招聘',
				label: '校园招聘'
			}],
			// 
			value: '',
			// 职位性质选项
			jobOptions:[{
				value: '全职',
				label: '全职'
			},{
				value: '兼职',
				label: '兼职'
			},{
				value: '管培',
				label: '管培'
			},{
				value: '实习',
				label: '实习'
			}],
			jobValue:'',
			// 工作地点选项
			areaOptions:[{
				value: '太原',
				label: '太原'
			},{
				value: '晋中',
				label: '晋中'
			}],
			areaValue:'',

			// 搜索内容
			Titile:'',
			// 当前页码
			pageNum:1,
			// 分页条数
			pageSize:10,
			// 总条数
			totalNum:0,

			// 职位列表
			jobList:[],
			// 最新职位列表
			newJobList:[],
		}
	},
	methods:{
		// 获取职位列表
		getJobList(){
			let obj = {
				pageNum:this.pageNum,
				pageSize:this.pageSize,
				Titile:this.Titile,
				jobtype:this.value,
				nature:this.jobValue,
				conter:this.areaValue,
			};
			JobList(obj).then((res) => {
				console.log(res);
				this.jobList = res.data.result;
				this.totalNum = res.data.totalNum;
			}).catch((err) => {
				console.log(err);
			});
		},
		// 点击搜索职位
		searchJob(){
			this.pageNum = 1;
			this.getJobList();
		},
		// 点击清楚按钮
		clearSearch(){
			this.pageNum = 1;
			this.Titile = '';
			this.jobtype = '';
			this.nature = '';
			this.conter = '';
			this.getJobList();
		},
		// 获取最新职位
		getNewJobList(){
			JobList().then((res) => {
				console.log(res);
				this.newJobList = res.data.result;
			}).catch((err) => {
				console.log(err);
			});
		},
		// 点击跳转详情
		tiDetial(data){
			console.log(data);
			sessionStorage.setItem('activeJob',JSON.stringify(data))
			this.$router.push('/talentRecruitmentDetial');
		},
	},
	created(){
		this.Titile = this.$route.query.inputSearch;
		this.getJobList();
		this.getNewJobList();
	}
}
</script>

<style lang="less" scoped>
.talentRecruitmentList{
	width: 100%;
	min-height: 100vh;
	background-color: #EFF0F0;
	box-sizing: border-box;
	.conentBox{
		width: 100%;
		height: auto;
		padding: 0 163px;
		box-sizing: border-box;
		display: flex;
		background-color: #EFF0F0;
		align-items: flex-start;
		.positionList{
			width: auto;
			flex: 1;
			background-color: #fff;
			overflow: hidden;
			.head{
				width: 100%;
				height: auto;
				padding: 0 41px;
				box-sizing: border-box;
				font-size: 28px;
				color: #3E3E3E;
				margin-top: 49px;
			}
			.screentext{
				width: 100%;
				padding: 0 42px;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: 85px;
				.title{
					font-size: 26px;
					color: #3E3E3E;
				}
				.result{
					display: flex;
					align-items: center;
					span{
						font-size: 22px;
						color: #767676;
						margin-left: 20px;
						position: relative;
						display: flex;
						align-items: center;
						&::after{
							content: '';
							width: 1px;
							height: 22px;
							display: block;
							background-color: #767676;
							margin-left: 20px;
						}
						&:nth-child(1){
							color: #0069B7;
						}
						&:last-child{
							cursor: pointer;
							&::after{
								content:none;
							}
						}
					}
				}
			}
			.inputBox{
				width: 100%;
				height: auto;
				padding: 0 30px;
				box-sizing: border-box;
				margin-top: 47px;
				.inputs{
					width: 100%;
					height: 80px;
					display: flex;
					align-items: center;
					box-shadow: 0px 17px 49px 32px rgba(112,112,112,0.05);
					border-radius: 38px;
					.left{
						flex: 1;
						display: flex;
						align-items: center;
						padding: 0 51px;
						box-sizing: border-box;
						i{
							font-size: 27px;
							color: #0069B7;
							flex: none;
						}
						input{
							flex: 1;
							height: 80px;
							border: 0;/*清除自带的2px的边框*/
							padding: 0;/*清除自带的padding间距*/
							outline: none;/*清除input点击之后的黑色边框*/
							font-size: 22px;
							margin-left: 34px;
						}
					}
					.btns{
						width: 240px;
						height: 80px;
						border-radius: 40px;
						background: linear-gradient(90deg, #0068B7 0%, #00B2C1 100%);
						text-align: center;
						line-height: 80px;
						font-size: 22px;
						color: #fff;
						cursor: pointer;
					}
				}
			}
			.selectBox{
				width: 100%;
				padding: 0 26px;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				margin-top: 24px;
				.el-select{
					margin-left: 20px;
					flex: 1;
					// box-shadow: 0px 17px 49px 32px rgba(112,112,112,0.05);
					&:first-child{
						margin-left: 0;
					}
				}
				/deep/.el-input__inner{
					height: 62px;
					border-radius: 31px;
				}
			}
			.listBox{
				width: 100%;
				height: auto;
				margin-top: 49px;
				.items{
					width: 100%;
					height: 156px;
					padding: 36px 42px;
					box-sizing: border-box;
					border-bottom: 1px solid #DCDDDD;
					box-sizing: border-box;
					.top{
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: space-between;
						.titles{
							width: auto;
							display: flex;
							align-items: center;
							.status{
								width: 28px;
								height: 28px;
								font-size: 20px;
								color: #fff;
								background-color: #EE7470;
								border-radius: 4px;
								text-align: center;
								line-height: 28px;
							}
							.title{
								font-size: 26px;
								color: #3E3E3E;
								margin-left: 12px;
								cursor: pointer;
							}
						}
						.times{
							font-size: 22px;
							color: #3E3E3E;
						}
					}
					.tips{
						width: auto;
						display: flex;
						align-items: center;
						margin-top: 33px;
						span{
							font-size: 22px;
							color: #3E3E3E;
							display: flex;
							align-items: center;
							&:nth-child(1){
								font-size: 26px;
								margin-right: 30px;
								&::after{
									content: '';
									display: flex;
									width: 1px;
									height: 26px;	
									background-color: #3E3E3E;
									margin-left: 20px;
								}
							}
						}
					}
				}
			}
			.pages{
				width: 100%;
				text-align: center;
				background-color: #EFF0F0;
				padding: 70px 0;
				box-sizing: border-box;
			}
		}
		.rightBox{
			width: 317px;
			// flex: none;
			display: inline;
			margin-left: 28px;
			background-color: #fff;
			border-radius: 2px;
			padding: 49px 36px;
			box-sizing: border-box;
			.title{
				font-size: 28px;
				color: #3E3E3E;
			}
			.conList{
				width: 100%;
				height: auto;
				margin-top: 54PX;
				.items{
					width: 100%;
					height: auto;
					display: flex;
					align-items: center;
					margin-top: 21px;
					cursor: pointer;
					&:nth-child(1){
						margin-top: 0;
					}
					.status{
						width: 28px;
						height: 28px;
						border-radius: 4px;
						background-color: #EE7470;
						font-size: 20px;
						color: #fff;
						text-align: center;
						line-height: 28px;
						flex: none;
						margin-right: 13px;
					}
					.names{
						flex: 1;
						font-size: 22px;
						color: #3E3E3E;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}
		}
	}
}
/deep/.el-pagination button{
    background-color: #fff !important;
}
/deep/.el-pager li{
    background-color: #fff !important;
}
</style>